import React, { useEffect, useState } from 'react'
import ContentDialogBox from '../../react_components/dialog_box/ContentDialogBox'
import NotificationMessages from '../../react_components/dialog_box/SuccessMessages'
import { Button, Card } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';


function DownloadLink() {
    const [open, setOpen] = useState(false)
    function downloadFile(link) {
        const linkObj = document.createElement("a");
        linkObj.target = "_blank";
        linkObj.href = link;
        linkObj.click();
    }
    return <>
        <Button onClick={()=>{
            setOpen(true)
        }} size='small' style={{
            color: "white",
        }}>
            <DownloadIcon sx={{
                fontSize: "30px",
            }} />
        </Button>
        <ContentDialogBox
            DialogContainerStyle={{
                borderRadius: "10px",
                color: "white",
                fontweight: "bolder",
                textAlign: "center",
                color: "black",
            }}
            maxWidth='xs'
            isOpen={open}
            onClose={() => { setOpen(false) }}
            content={<>
                <hr style={{ border: "1px solid white" }} />
                <h2>Download e-Link</h2>
                <hr style={{ border: "1px solid white" }} />
                <img src="https://open-editor-assets.s3.ap-south-1.amazonaws.com/assets/e-Link.svg" style={{
                    maxWidth: "80px",
                }} />
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <Card sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "0px 15px",
                        margin: "10px",
                    }}>
                        <img src="https://educobot.com/imgs/robotics/windows.png" style={{
                            maxWidth: "80px",
                        }} />
                        <Button onClick={() => {
                            downloadFile("https://storage.googleapis.com/open-editor-assets/assets/eLink-2.0.0.msi")
                        }}>
                            <DownloadIcon sx={{
                                fontSize: "30px",
                                color: "gray"
                            }} />
                        </Button>
                    </Card>

                    <Card sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "15px",
                        margin: "10px",
                    }}>
                        <img src="https://open-editor-assets.s3.ap-south-1.amazonaws.com/website-statics/mac-os.png" style={{
                            maxWidth: "80px",
                            opacity:"0.65"
                        }} />
                        <Button onClick={() => {
                            downloadFile("https://open-editor-assets.s3.ap-south-1.amazonaws.com/assets/e-Link-0.0.1-mac.pkg")
                        }}>
                            <DownloadIcon sx={{
                                fontSize: "30px",
                                color: "gray"
                            }} />
                        </Button>
                    </Card>
                </div>
            </>}
        />

    </>
}

export default DownloadLink